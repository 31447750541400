import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { TrackingData } from 'src/app/shared/models/trackingData';

@Injectable()
export class TrackingService {
    endpoint = environment.trackingInfoApi;

    constructor(private http: HttpClient) {
    }

    getTracking(trackingNumber: string): Observable<TrackingData> {
        // const url = 'fb/gtmFastwayGet';
        const url = 'https://us-central1-courier-service-33dcf.cloudfunctions.net/gtmGetTrackingInfo';

        const data = {
          apiKey: 'ANG',
          trackingNumber: trackingNumber,
        };

        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Request-Method': '*',

        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          })
        };
        return this.http.post<TrackingData>(url, data, httpOptions);
      }
}
